import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { RevisionSetResponse, RevisionSetStatus } from "@revv/data";

import { useStateContext } from "../context";

const baseUrl = "/v1/revision-sets";

export function useRevisionSet(id: string) {
  const { apiClient } = useStateContext();
  const queryClient = useQueryClient();

  const queryKey = ["revisionSet", id];

  const { data, error, isLoading } = useQuery({
    refetchOnWindowFocus: false,
    queryKey,
    queryFn: async () => {
      const response = await apiClient.get<RevisionSetResponse>(
        `${baseUrl}/${id}`
      );

      return response.data;
    },
  });

  const reviewMutation = useMutation({
    mutationFn: async (
      action: RevisionSetStatus.APPROVED | RevisionSetStatus.REJECTED
    ) => {
      const res = await apiClient.post<RevisionSetResponse>(
        `${baseUrl}/${id}/${action === RevisionSetStatus.APPROVED ? "approve" : "reject"}`
      );

      return res.data;
    },
    onSuccess: (data) => {
      queryClient.setQueryData(queryKey, data);
    },
  });

  const restoreMutation = useMutation({
    mutationFn: async () => {
      const res = await apiClient.post<RevisionSetResponse>(
        `${baseUrl}/${id}/restore`
      );

      return res.data;
    },
    onSuccess: (data) => {
      queryClient.setQueryData(queryKey, data);
    },
  });

  const submitMutation = useMutation({
    mutationFn: async () => {
      const res = await apiClient.post<RevisionSetResponse>(
        `${baseUrl}/${id}/submit`
      );

      return res.data;
    },
    onSuccess: (data) => {
      queryClient.setQueryData(queryKey, data);
    },
  });

  return {
    revisionSet: data?.revisionSet,
    error,
    isLoading,
    reviewMutation: {
      mutateAsync: reviewMutation.mutateAsync,
      isPending: reviewMutation.isPending,
      error: reviewMutation?.error || undefined,
    },
    restoreMutation: {
      mutateAsync: restoreMutation.mutateAsync,
      isPending: restoreMutation.isPending,
      error: restoreMutation?.error || undefined,
    },
    submitMutation: {
      mutateAsync: submitMutation.mutateAsync,
      isPending: submitMutation.isPending,
      error: submitMutation?.error || undefined,
    },
  };
}
