import { useStateContext } from "./context";

const BASE_URL = "/v1/webhook_endpoints";

export function useWebhookEndpoint() {
  const { apiClient } = useStateContext();

  const insertWebhookEndpoint = async (endpoint: string) => {
    const payload = {
      endpoint: endpoint,
    };
    const response = await apiClient.post(`${BASE_URL}/insert`, payload);
    return response.data.newEndpoint;
  };

  const deleteWebhookEndpoint = async (id: number) => {
    const payload = {
      id: id,
    };
    await apiClient.post(`${BASE_URL}/delete`, payload);
    return;
  };

  const getWebhookEndpoints = async (queryParams: {
    skip: number;
    limit: number;
  }) => {
    const response = await apiClient.get(`${BASE_URL}/`, {
      params: queryParams,
    });

    return {
      endpoints: response.data.endpoints,
      nextPageCount: response.data.nextPageCount,
    };
  };

  return {
    insertWebhookEndpoint,
    getWebhookEndpoints,
    deleteWebhookEndpoint,
  };
}
