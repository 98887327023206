import { useQuery } from "@tanstack/react-query";

import { ListSensorAdasSystemsResponse } from "@revv/data";

import { useStateContext } from "../context";

const baseUrl = "/v1/sensors/adas-systems";

export function useSensorAdasSystems() {
  const { apiClient } = useStateContext();

  const { data, error, isLoading } = useQuery({
    refetchOnWindowFocus: false,
    queryKey: ["sensorAdasSystems"],
    queryFn: async () => {
      const response =
        await apiClient.get<ListSensorAdasSystemsResponse>(baseUrl);

      return response.data.adasSystems;
    },
  });

  return {
    adasSystems: data,
    error,
    isLoading,
  };
}
