import { AxiosInstance } from "axios";

import { RevisionSetsResponse } from "@revv/data";

import { useStateContext } from "../context";
import { usePagedQuery } from "../usePagedQuery";

const pageSize = 100;
const baseUrl = "/v1/revision-sets";

export type RevisionSetFilterParams = {
  status?: string;
  description?: string;
  createdBy?: string;
  reviewedBy?: string;
};

export type RevisionSetParams = RevisionSetFilterParams & {
  sortBy: string;
  sortOrder: "asc" | "desc" | "";
};

export function useRevisionSets(
  params: RevisionSetParams,
  onRefresh?: () => void
) {
  const { apiClient } = useStateContext();

  const queryKey = ["revisionSets", JSON.stringify(params)];

  const { getPage, count, error } = usePagedQuery(
    queryKey,
    async (pageNumber: number) => {
      const res = await fetchRevisionSets(apiClient, params, pageNumber);

      return {
        items: res.revisionSets,
        count: res.count,
      };
    },
    onRefresh
  );

  return {
    getPage,
    count,
    pageSize,
    error,
  };
}

async function fetchRevisionSets(
  apiClient: AxiosInstance,
  params: RevisionSetParams,
  pageNumber: number
) {
  const queryParams = convertParams(params);

  queryParams.append("pageNumber", pageNumber.toString());
  queryParams.append("pageSize", pageSize.toString());

  const res = await apiClient.get<RevisionSetsResponse>(baseUrl, {
    params: queryParams,
  });

  return res.data;
}

function convertParams(params: RevisionSetParams) {
  const queryParams = new URLSearchParams();

  queryParams.append("status.equals", params.status ?? "");
  queryParams.append("description.contains", params.description ?? "");
  queryParams.append("createdBy.contains", params.createdBy ?? "");
  queryParams.append("reviewedBy.contains", params.reviewedBy ?? "");

  queryParams.append("sortBy", params.sortBy);
  queryParams.append("order", params.sortOrder);

  const fields = [...queryParams.keys()];

  for (const key of fields) {
    if (!queryParams.get(key)) {
      queryParams.delete(key);
    }
  }

  return queryParams;
}
