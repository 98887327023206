import { useQuery } from "@tanstack/react-query";

import { SandboxesResponse } from "@revv/data";

import { useStateContext } from "../context";

const baseUrl = "/v1/sandboxes";

export function useSandboxes() {
  const { apiClient } = useStateContext();

  const queryKey = ["sandboxes"];

  const { data, error, isLoading } = useQuery({
    refetchOnWindowFocus: false,
    queryKey,
    queryFn: async () => {
      const response = await apiClient.get<SandboxesResponse>(baseUrl);
      return response.data;
    },
  });

  return {
    sandboxes: data?.sandboxes,
    error: error ?? undefined,
    isLoading,
  };
}
