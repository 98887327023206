import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import {
  TableColumnState,
  TableColumnStateData,
  UpdateTableColumnStatesPayload,
} from "@revv/data";

import { useStateContext } from "./context";

const BASE_URL = "/v1/table_column_states";

export function useTableColumnState() {
  const { apiClient } = useStateContext();
  const queryClient = useQueryClient();
  const queryKey = ["tableStates"];

  const { data } = useQuery<TableColumnStateData>({
    refetchOnWindowFocus: false,
    queryKey: queryKey,
    queryFn: async () => {
      const { data } = await apiClient.get<{
        tableColumnStates: TableColumnState[];
      }>(`${BASE_URL}/`);

      const tableStates = <TableColumnStateData>{};
      data.tableColumnStates.forEach((state) => {
        tableStates[state.table_name] = state.state;
      });

      return tableStates;
    },
  });
  const updateTablesColumnStateMutation = useMutation({
    mutationFn: async (payload: UpdateTableColumnStatesPayload) => {
      await apiClient.post(`${BASE_URL}/upsert`, {
        tableName: payload.tableName,
        newState: payload.newState,
      });
      return payload;
    },
    onSuccess: (payload: UpdateTableColumnStatesPayload) => {
      queryClient.setQueryData<TableColumnStateData>(queryKey, (data) => {
        if (!data) {
          return;
        }
        const updatedTableStates = JSON.parse(JSON.stringify(data));
        if (updatedTableStates) {
          updatedTableStates[payload.tableName] = payload.newState;
        }

        return updatedTableStates;
      });
    },
  });

  return {
    tableState: data,
    updateTableColumnStates: async (
      payload: UpdateTableColumnStatesPayload
    ) => {
      await updateTablesColumnStateMutation.mutateAsync(payload);
    },
  };
}
