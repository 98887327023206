import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import {
  CreateKeywordGroupRequest,
  CreateKeywordGroupResponse,
  DeleteKeywordGroupRequest,
  DeleteKeywordGroupResponse,
  GetKeywordGroupResponse,
  UpdateKeywordGroupRequest,
  UpdateKeywordGroupResponse,
} from "@revv/data";

import { useStateContext } from "../context";

const baseUrl = "/v1/keyword-groups";

export function useKeywordGroup(sandboxId: string, id: "new" | string) {
  const { apiClient } = useStateContext();
  const queryClient = useQueryClient();
  const queryKey = ["keyword-group", sandboxId, id];

  const { data, error, isLoading } = useQuery({
    refetchOnWindowFocus: false,
    queryKey: queryKey,
    enabled: id !== "new",
    queryFn: async () => {
      const response = await apiClient.get<GetKeywordGroupResponse>(
        `${baseUrl}/${sandboxId}/${id}`
      );

      return response.data.keywordGroup;
    },
  });

  const updateMutation = useMutation({
    mutationFn: async (payload: UpdateKeywordGroupRequest) => {
      const res = await apiClient.put<UpdateKeywordGroupResponse>(
        `${baseUrl}/${sandboxId}/${id}`,
        payload
      );

      return res.data;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey });
      await queryClient.invalidateQueries({
        queryKey: ["keyword-groups"],
        exact: false,
      });
    },
  });

  const createMutation = useMutation({
    mutationFn: async (payload: CreateKeywordGroupRequest) => {
      const res = await apiClient.post<CreateKeywordGroupResponse>(
        `${baseUrl}/${sandboxId}`,
        payload
      );

      return res.data;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["keyword-groups"],
        exact: false,
      });
    },
  });

  const deleteMutation = useMutation({
    mutationFn: async (payload: DeleteKeywordGroupRequest) => {
      const res = await apiClient.delete<DeleteKeywordGroupResponse>(
        `${baseUrl}/${sandboxId}/${id}`,
        {
          data: payload,
        }
      );

      return res.data;
    },
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey });
      queryClient.invalidateQueries({
        queryKey: ["keyword-groups"],
        exact: false,
      });
    },
  });

  return {
    keywordGroup: data,
    error,
    isLoading,
    updateMutation: {
      mutateAsync: updateMutation.mutateAsync,
      isPending: updateMutation.isPending,
      error: updateMutation?.error || undefined,
    },
    createMutation: {
      mutateAsync: createMutation.mutateAsync,
      isPending: createMutation.isPending,
      error: createMutation?.error || undefined,
    },
    deleteMutation: {
      mutateAsync: deleteMutation.mutateAsync,
      isPending: deleteMutation.isPending,
      error: deleteMutation?.error || undefined,
    },
  };
}
