import { useMutation } from "@tanstack/react-query";

import { useStateContext } from "./context";

const BASE_URL = "/v1/quickbooks";

type QuickbooksConnectParams = {
  selectedMaster?: string;
  redirectUrl?: string;
};

function convertParams(params: QuickbooksConnectParams) {
  const queryParams = new URLSearchParams();

  if (params?.selectedMaster) {
    queryParams.append("email", params.selectedMaster);
  }

  if (params?.redirectUrl) {
    queryParams.append("redirect_url", params.redirectUrl);
  }

  const fields = [...queryParams.keys()];

  for (const key of fields) {
    if (!queryParams.get(key)) {
      queryParams.delete(key);
    }
  }

  return queryParams;
}

export function useQuickbooks({
  selectedMaster,
  redirectUrl,
}: QuickbooksConnectParams) {
  const { apiClient } = useStateContext();

  const connectMutation = useMutation({
    mutationFn: async () => {
      const queryParams = convertParams({
        selectedMaster,
        redirectUrl: redirectUrl || "",
      });
      const res = await apiClient.get(`${BASE_URL}/connect_to_quickbooks`, {
        params: queryParams,
      });
      return res.data;
    },
  });

  const disconnectMutation = useMutation({
    mutationFn: async () => {
      await apiClient.post(`${BASE_URL}/disconnect`);
      return;
    },
  });

  return {
    connectMutation: {
      mutateAsync: connectMutation.mutateAsync,
      isPending: connectMutation.isPending,
      error: connectMutation.error || undefined,
    },
    disconnectMutation: {
      mutateAsync: disconnectMutation.mutateAsync,
      isPending: disconnectMutation.isPending,
      error: disconnectMutation.error || undefined,
    },
  };
}
