import { useMutation } from "@tanstack/react-query";

import { OperationSensor } from "@revv/data";

import { useStateContext } from "./context";

export type SendReportFeedbackBody = {
  name: string;
  issueDescription: string;
  issues: string[];
  estimateId: number;
  lineItem: OperationSensor;
};

export function useReportFeedback() {
  const { apiClient } = useStateContext();

  const sendReportFeedbackMutation = useMutation({
    mutationFn: async (body: SendReportFeedbackBody) => {
      await apiClient.post("/v1/zendesk/create", body);
    },
  });

  return {
    sendReportFeedbackMutation,
  };
}
