import { useQuery } from "@tanstack/react-query";
import { isEqual } from "lodash-es";

import { UserPermissions } from "@revv/data";

import { useStateContext } from "./context";

const QUERY_KEY = ["userPermissions"];

type UsePermissionsResponse = {
  permissions: typeof UserPermissions;
};

/**
 * DO NOT USE this hook to get permissions.
 * Use UserPermissions enum from @revv/data instead.
 */
export function usePermissions() {
  const { apiClient } = useStateContext();
  const { data, isLoading, error } = useQuery({
    queryKey: QUERY_KEY,
    // not reloading for at least 10 hours
    staleTime: 10 * 60 * 60 * 1000,
    queryFn: async () => {
      const res =
        await apiClient.get<UsePermissionsResponse>("/v1/permissions");

      return res.data.permissions;
    },
  });

  if (
    process.env.NODE_ENV === "development" &&
    data &&
    !isEqual(data, UserPermissions)
  ) {
    throw new Error(
      "UserPermissions enum is out of sync with the server. Please update the enum."
    );
  }

  return {
    permissions: data,
    error,
    isLoading,
  };
}
