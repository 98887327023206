import { useQuery } from "@tanstack/react-query";

import { useCustomer } from "@revv/state";

import { useStateContext } from "./context";

const BASE_URL = "/v1/technicians/all";

export function useTechnicians() {
  const { apiClient } = useStateContext();
  const { selectedMaster } = useCustomer();

  const { data, refetch, error, isLoading } = useQuery({
    queryKey: ["technicians", selectedMaster],
    queryFn: async () => {
      const response = await apiClient.get(`${BASE_URL}/${selectedMaster}`);

      return response.data;
    },
  });

  return {
    technicians: data?.technicians,
    refetch,
    error,
    isLoading,
  };
}
