import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { pick } from "lodash-es";

import {
  CreateCustomerPayload,
  CustomerInfo,
  CustomerResponse,
} from "@revv/data";

import { useStateContext } from "./context";

const BASE_URL = "/v1/customer";

type CustomerData = {
  customerInfo?: CustomerResponse["customer_info"];
  selectedMaster?: CustomerResponse["selected_master"];
  userContext?: CustomerResponse["user_context"];
  zendeskToken?: CustomerResponse["zendesk_token"];
};

export function useCustomer() {
  const { apiClient, email } = useStateContext();
  const queryClient = useQueryClient();
  const queryKey = ["customer", email];
  const { data, error: customerError } = useQuery<CustomerData>({
    refetchOnWindowFocus: false,
    queryKey: queryKey,
    queryFn: async () => {
      if (email) {
        const { data } = await apiClient.get<CustomerResponse>(
          `${BASE_URL}/${email}`
        );

        return {
          customerInfo: data.customer_info,
          selectedMaster: data.selected_master,
          userContext: data.user_context,
          zendeskToken: data.zendesk_token,
        };
      }

      return {};
    },
  });

  const updateCustomerInfo = (customerInfo: Partial<CustomerInfo>) => {
    queryClient.setQueryData<CustomerData>(queryKey, (data) => {
      if (!data?.customerInfo) {
        return;
      }

      return {
        ...data,
        customerInfo: {
          ...data.customerInfo,
          ...customerInfo,
        },
      };
    });
  };

  const updateSelectedShopMutation = useMutation({
    mutationFn: async (selectedShopNumber: number) => {
      const payload = {
        shop_number: selectedShopNumber,
        email_id: data?.customerInfo?.email_id,
      };

      await apiClient.post(`${BASE_URL}/update_selected_shop_number`, payload);

      return selectedShopNumber;
    },
    onSuccess: (selectedShopNumber) => {
      updateCustomerInfo({ selected_shop_number: selectedShopNumber });
    },
  });

  const acceptTermsMutation = useMutation({
    mutationFn: async () => {
      try {
        const { data } = await apiClient.post(
          "/v1/terms_and_conditions/accept_terms"
        );
        return data;
      } catch (error: unknown) {
        const error_ =
          error instanceof AxiosError
            ? new Error(error.response?.data.error)
            : new Error("Something went wrong");
        throw error_;
      }
    },
    onSuccess: () => {
      updateCustomerInfo({ has_accepted_terms: true });
    },
  });

  const createCustomer = useMutation({
    mutationFn: async (postData: CreateCustomerPayload) => {
      try {
        const { data } = await apiClient.post(
          `${BASE_URL}/create_service_email`,
          postData
        );
        return data;
      } catch (error: unknown) {
        const error_ =
          error instanceof AxiosError
            ? new Error(error.response?.data.error)
            : new Error("Something went wrong");
        throw error_;
      }
    },
  });

  const deleteCustomer = useMutation({
    mutationFn: async (emailId: string) => {
      const postData = {
        service_email: emailId,
      };

      try {
        const { data } = await apiClient.post(
          `${BASE_URL}/delete_service_email`,
          postData
        );
        return data;
      } catch (error: unknown) {
        const error_ =
          error instanceof AxiosError
            ? new Error(error.response?.data.error)
            : new Error("Something went wrong");
        throw error_;
      }
    },

    onMutate: () => {
      queryClient.setQueryData<CustomerData>(queryKey, (data) => {
        if (!data?.customerInfo) {
          return;
        }

        return {
          ...data,
          customerInfo: {
            ...data.customerInfo,
            is_deleting: true,
          },
        };
      });
    },

    onSuccess: () => {
      queryClient.setQueryData<CustomerData>(queryKey, (data) => {
        if (!data?.customerInfo) {
          return;
        }

        return {
          ...data,
          customerInfo: {
            ...data.customerInfo,
            is_deleting: false,
          },
        };
      });
    },
  });

  const enrollRevvPlusMutation = useMutation({
    mutationFn: async () => {
      const res = await apiClient.post(`${BASE_URL}/enroll-revv-plus`);

      return res.data;
    },
    onSuccess: () => {
      updateCustomerInfo({ revv_plus_enabled: true });
    },
  });

  return {
    ...data,
    setCustomerInformation: (customerInfo: CustomerInfo) =>
      updateCustomerInfo(customerInfo),
    updateSelectedShop: (selectedShop: number) => {
      updateSelectedShopMutation.mutate(selectedShop);
    },
    acceptTermsMutation: {
      ...pick(acceptTermsMutation, [
        "mutateAsync",
        "isPending",
        "isSuccess",
        "isError",
      ]),
      error: acceptTermsMutation.error,
    },
    createCustomer: {
      mutate: createCustomer.mutateAsync,
      error: createCustomer.error,
      isPending: createCustomer.isPending,
    },
    deleteCustomer: {
      mutate: deleteCustomer.mutateAsync,
      error: deleteCustomer.error,
    },
    customerError,
    enrollRevvPlus: {
      mutateAsync: enrollRevvPlusMutation.mutateAsync,
      isPending: enrollRevvPlusMutation.isPending,
      error: enrollRevvPlusMutation?.error || undefined,
    },
  };
}
