import { useMutation, useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";

import { Role } from "@revv/data";

import { useStateContext } from "./context";

const BASE_URL = "/v1/user_management";

type RolesResponse = {
  user_roles: Role[];
  nextPageCount: number;
};

type UpsertRolesProps = {
  roleId?: string;
  selectedPermissionIds: number[];
  roleName: string;
};

export function useRoles(pageNumber = 1, pageSize = 10) {
  const { apiClient } = useStateContext();

  const { data, refetch, error, isLoading } = useQuery<RolesResponse, Error>({
    refetchOnWindowFocus: false,
    queryKey: ["roles"],
    queryFn: async () => {
      try {
        const response = await apiClient.get<RolesResponse>(
          `${BASE_URL}?pageNumber=${pageNumber}&PAGE_SIZE=${pageSize}`
        );
        return response.data;
      } catch (error: unknown) {
        const error_ =
          error instanceof AxiosError
            ? new Error(error.response?.data.error)
            : new Error("Something went wrong");
        throw error_;
      }
    },
  });

  const handleDeleteUserRole = useMutation({
    mutationFn: async (id: string) => {
      try {
        await apiClient.post(`/v1/user_management/delete`, {
          role_id: id,
        });
      } catch (error: unknown) {
        const error_ =
          error instanceof AxiosError
            ? new Error(error.response?.data.message)
            : new Error("Something went wrong");
        throw error_;
      }
    },
    onSuccess: () => {
      refetch();
    },
  });

  const handleCreateorEditUserRole = useMutation({
    mutationFn: async ({
      roleId,
      selectedPermissionIds,
      roleName,
    }: UpsertRolesProps) => {
      await apiClient.post(`/v1/user_management/upsert`, {
        role_id: roleId,
        selected_permission_ids: selectedPermissionIds,
        role_name: roleName,
      });
    },
    onSuccess: () => {
      refetch();
    },
  });

  return {
    roles: data?.user_roles,
    nextPageCount: data?.nextPageCount,
    isLoading,
    error,
    refetch,
    deleteRole: {
      mutate: handleDeleteUserRole.mutateAsync,
      error: handleDeleteUserRole.error,
    },
    upsertRole: handleCreateorEditUserRole,
  };
}
