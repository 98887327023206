import { useStateContext } from "./context";

const BASE_URL = "/v1/api_keys";

export function useApiKey() {
  const { apiClient } = useStateContext();

  const generateApiKey = async (name: string) => {
    const payload = {
      name: name,
    };
    const response = await apiClient.post(`${BASE_URL}/insert`, payload);
    return response.data.key;
  };

  const regenerateApiKey = async (id: number) => {
    const payload = {
      id: id,
    };
    const response = await apiClient.post(`${BASE_URL}/regenerate`, payload);
    return response.data.key;
  };

  const deleteApiKey = async (id: number) => {
    const payload = {
      id: id,
    };
    await apiClient.post(`${BASE_URL}/delete`, payload);
    return;
  };

  const getApiKeys = async (queryParams: { skip: number; limit: number }) => {
    const response = await apiClient.get(`${BASE_URL}/`, {
      params: queryParams,
    });

    return {
      keys: response.data.keys,
      nextPageCount: response.data.nextPageCount,
    };
  };

  return {
    generateApiKey,
    getApiKeys,
    regenerateApiKey,
    deleteApiKey,
  };
}
