import { useMutation, useQuery } from "@tanstack/react-query";

import { MAIN_SANDBOX_ID, SandboxResponse } from "@revv/data";

import { useStateContext } from "../context";

const baseUrl = "/v1/sandboxes";

export function useSandbox(sandboxId: string) {
  const { apiClient } = useStateContext();

  const queryKey = ["sandbox", sandboxId];

  const { data, error, isLoading } = useQuery({
    refetchOnWindowFocus: false,
    queryKey,
    queryFn: async () => {
      let data: SandboxResponse;

      if (sandboxId === MAIN_SANDBOX_ID) {
        data = {
          sandbox: {
            id: 0,
            createdAt: new Date(0).toISOString(),
            name: "Main",
            sandboxId: MAIN_SANDBOX_ID,
            revisionSetId: 0,
          },
        };
      } else {
        const response = await apiClient.get<SandboxResponse>(
          `${baseUrl}/${sandboxId}`
        );
        data = response.data;
      }

      return data;
    },
  });

  const createMutation = useMutation({
    mutationFn: async (name: string) => {
      const res = await apiClient.post<SandboxResponse>(`${baseUrl}/create`, {
        name,
      });

      return res.data;
    },
  });

  const duplicateMutation = useMutation({
    mutationFn: async () => {
      const res = await apiClient.post<SandboxResponse>(
        `${baseUrl}/duplicate`,
        {
          sandboxId,
        }
      );

      return res.data;
    },
  });

  const deleteMutation = useMutation({
    mutationFn: async () => {
      const res = await apiClient.post<SandboxResponse>(`${baseUrl}/delete`, {
        sandboxId,
      });

      return res.data;
    },
  });

  return {
    sandbox: data?.sandbox,
    error: error ?? undefined,
    isLoading,
    createMutation: {
      mutateAsync: createMutation.mutateAsync,
      isPending: createMutation.isPending,
      error: createMutation?.error || undefined,
    },
    duplicateMutation: {
      mutateAsync: duplicateMutation.mutateAsync,
      isPending: duplicateMutation.isPending,
      error: duplicateMutation?.error || undefined,
    },
    deleteMutation: {
      mutateAsync: deleteMutation.mutateAsync,
      isPending: deleteMutation.isPending,
      error: deleteMutation?.error || undefined,
    },
  };
}
