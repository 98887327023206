import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { AxiosInstance } from "axios";
import { createContext, ReactNode, useContext, useMemo, useState } from "react";

type StateContextType = {
  apiClient: AxiosInstance;
  email?: string;
};

export const StateContext = createContext<StateContextType | undefined>(
  undefined
);

type StateProviderProps = {
  apiClient: AxiosInstance;
  email?: string;
  children: ReactNode;
};

export function StateProvider({
  apiClient,
  email,
  children,
}: StateProviderProps) {
  const [queryClient] = useState(() => new QueryClient());
  const contextValue = useMemo(
    () => ({ apiClient, email }),
    [email, apiClient]
  );

  return (
    <StateContext.Provider value={contextValue}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </StateContext.Provider>
  );
}

export function useStateContext() {
  const stateContext = useContext(StateContext);

  if (!stateContext) {
    throw new Error("StateContext is not defined");
  }

  return stateContext;
}
