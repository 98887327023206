import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { CreateInsuranceResponse, InsuranceResponse } from "@revv/data";

import { useStateContext } from "./context";

export function useInsurances() {
  const { apiClient } = useStateContext();
  const queryClient = useQueryClient();

  const queryKey = ["Insurances"];

  const { data, error, isLoading } = useQuery<InsuranceResponse>({
    queryKey,
    queryFn: async () => {
      const { data } =
        await apiClient.get<InsuranceResponse>("/v1/insurances/");
      return data;
    },
  });

  const addInsuranceMutation = useMutation({
    mutationFn: async (name: string) => {
      const { data } = await apiClient.post<CreateInsuranceResponse>(
        "/v1/insurances/create",
        {
          name: name,
        }
      );

      return data.insurance;
    },
    onSuccess: (insurance) => {
      queryClient.setQueryData<InsuranceResponse>(queryKey, (data) => {
        if (!data) {
          return;
        }

        const newInsuranceArray = data?.insurances;
        newInsuranceArray.unshift(insurance);
        return {
          insurances: newInsuranceArray,
        };
      });
    },
  });

  return {
    insurances: data?.insurances || [],
    isLoading,
    error,
    createInsurance: async (name: string) => {
      await addInsuranceMutation.mutateAsync(name);
    },
  };
}
