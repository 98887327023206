import { useMutation, useQuery } from "@tanstack/react-query";

import { Country, GetReportResponse } from "@revv/data";

import { useStateContext } from "../context";
import { baseUrl } from "./useReports";

export type ReportDetailsParams = {
  reportType?: "estimate" | "vin" | "mechanical";
  type: "internal" | "customer";
  country?: Country;
};

export function useReport(
  id: string,
  sandboxId: string,
  params: ReportDetailsParams
) {
  const { apiClient } = useStateContext();

  const { data, error, isLoading } = useQuery({
    refetchOnWindowFocus: false,
    queryKey: ["reports", sandboxId, id, JSON.stringify(params)],
    queryFn: async () => {
      const queryParams = convertParams(params);

      const response = await apiClient.get<GetReportResponse>(
        `${baseUrl}/${sandboxId}/${id}/${params.type}`,
        { params: queryParams }
      );

      return response.data;
    },
  });

  const downloadEstimateMutation = useMutation({
    mutationFn: async () => {
      const response = await apiClient.get(
        `${baseUrl}/${sandboxId}/${id}/customer/download-estimate`,
        {
          responseType: "blob",
        }
      );

      await new Promise((res) => setTimeout(res, 500));

      const blob = response.data;
      const contentType = response.headers["content-type"];

      return { blob, contentType };
    },
  });

  return {
    report: data,
    error,
    isLoading,
    downloadEstimateMutation: {
      mutateAsync: downloadEstimateMutation.mutateAsync,
      isPending: downloadEstimateMutation.isPending,
      error: downloadEstimateMutation?.error || undefined,
    },
  };
}

function convertParams(params: ReportDetailsParams) {
  const queryParams = new URLSearchParams();

  if (params.country) {
    queryParams.append("country", params.country);
  }

  if (params.reportType) {
    queryParams.append("type", params.reportType);
  }

  return queryParams;
}
