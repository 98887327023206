import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { WorkOrderStatus, WorkOrderStatusResponse } from "@revv/data";

import { useStateContext } from "./context";

const BASE_URL = "/v1/work_orders";

type WorkOrderStatusData = {
  defaultWorkOrderStatus?: WorkOrderStatus;
  workOrderStatuses?: WorkOrderStatus[];
};

export function useWorkOrderStatuses() {
  const { apiClient } = useStateContext();
  const queryClient = useQueryClient();
  const queryKey = ["workOrderStatuses"];

  const { data } = useQuery<WorkOrderStatusData>({
    refetchOnWindowFocus: false,
    queryKey: queryKey,
    queryFn: async () => {
      const workOrderStatusResponse =
        await apiClient.get<WorkOrderStatusResponse>(`${BASE_URL}/statuses`);
      return {
        workOrderStatuses: workOrderStatusResponse.data.statuses,
        defaultWorkOrderStatus: workOrderStatusResponse.data.statuses?.[1],
      };
    },
  });

  const updateWorkOrderStatusesMutation = useMutation({
    mutationFn: async (newStatuses: WorkOrderStatus[]) => {
      const res = await apiClient.post(`${BASE_URL}/save-statuses`, {
        statuses: newStatuses,
      });
      return res.data.statuses;
    },
    onSuccess: (newStatuses: WorkOrderStatus[]) => {
      queryClient.setQueryData<WorkOrderStatusData>(queryKey, (data) => {
        if (!data) {
          return;
        }
        return {
          workOrderStatuses: newStatuses,
          defaultWorkOrderStatus: newStatuses?.[1],
        };
      });
    },
  });

  const deleteWorkOrderStatusMutation = useMutation({
    mutationFn: async (statusId: number) => {
      const res = await apiClient.post(`${BASE_URL}/deleteStatus`, {
        status_id: statusId,
      });
      return res.data.statuses;
    },
    onSuccess: (newStatuses: WorkOrderStatus[]) => {
      queryClient.setQueryData<WorkOrderStatusData>(queryKey, (data) => {
        if (!data) {
          return;
        }
        return {
          workOrderStatuses: newStatuses,
          defaultWorkOrderStatus: newStatuses?.[1],
        };
      });
    },
  });

  return {
    defaultWorkOrderStatus: data?.defaultWorkOrderStatus,
    workOrderStatuses: data?.workOrderStatuses,
    updateWorkOrderStatuses: async (newStatuses: WorkOrderStatus[]) => {
      await updateWorkOrderStatusesMutation.mutateAsync(newStatuses);
    },
    deleteWorkOrderStatus: async (statusId: number) => {
      await deleteWorkOrderStatusMutation.mutateAsync(statusId);
    },
  };
}
