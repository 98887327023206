import { AxiosInstance } from "axios";

import { ListKeywordGroupsResponse } from "@revv/data";

import { useStateContext } from "../context";
import { usePagedQuery } from "../usePagedQuery";

const baseUrl = "/v1/keyword-groups";

export type KeywordGroupsFilterParams = {
  name?: string;
  description?: string;
  type?: string;
};

export type KeywordGroupsParams = KeywordGroupsFilterParams & {
  sortBy: string;
  sortOrder: "asc" | "desc" | "";
};

export function useKeywordGroups(
  sandboxId: string,
  params: KeywordGroupsParams,
  onRefresh?: () => void,
  pageSize = 100
) {
  const { apiClient } = useStateContext();

  const queryKey = [
    "keyword-groups",
    sandboxId,
    JSON.stringify(params),
    pageSize,
  ];

  const { getPage, allItems, count, error } = usePagedQuery(
    queryKey,
    async (pageNumber: number) => {
      const res = await fetchKeywordGroups(
        apiClient,
        sandboxId,
        params,
        pageNumber,
        pageSize
      );

      return {
        items: res.keywordGroups,
        count: res.count,
      };
    },
    onRefresh
  );

  return {
    keywordGroups: allItems,
    getPage,
    count,
    pageSize,
    error,
  };
}

async function fetchKeywordGroups(
  apiClient: AxiosInstance,
  sandboxId: string,
  params: KeywordGroupsParams,
  pageNumber: number,
  pageSize: number
) {
  const queryParams = convertParams(params);

  queryParams.append("pageNumber", pageNumber.toString());
  queryParams.append("pageSize", pageSize.toString());

  const res = await apiClient.get<ListKeywordGroupsResponse>(
    `${baseUrl}/${sandboxId}`,
    {
      params: queryParams,
    }
  );

  return res.data;
}

function convertParams(params: KeywordGroupsParams) {
  const queryParams = new URLSearchParams();

  queryParams.append("name.contains", params.name ?? "");
  queryParams.append("description.contains", params.description ?? "");
  queryParams.append("type.equals", params.type ?? "");
  queryParams.append("sortBy", params.sortBy);
  queryParams.append("order", params.sortOrder);

  const fields = [...queryParams.keys()];

  for (const key of fields) {
    if (!queryParams.get(key)) {
      queryParams.delete(key);
    }
  }

  return queryParams;
}
