import { useQuery } from "@tanstack/react-query";

import {
  SubAccount,
  SubAccountShop,
  SubAccountsResponse,
  UserPermissions,
} from "@revv/data";

import { useStateContext } from "./context";
import { useCustomer } from "./useCustomer";

type UseSubAccountsParams = {
  pageNumber?: number;
  pageSize?: number;
  filter?: string;
};

export function useSubAccounts(email?: string, params?: UseSubAccountsParams) {
  const { customerInfo } = useCustomer();
  const { apiClient } = useStateContext();

  const isUserAuthorized = customerInfo?.permissions?.includes(
    UserPermissions.VIEW_SUB_ACCOUNTS
  );

  const { data } = useQuery({
    queryKey: ["subAccounts", email, JSON.stringify(params)],
    queryFn: async () => {
      const paramsString = parseParams(params);
      const response = await apiClient.get<SubAccountsResponse>(
        `/v1/customer/sub_accounts/${email}${paramsString ? `?${paramsString}` : ""}`
      );
      return response.data;
    },
    enabled: isUserAuthorized && !!email,
    select: (data: SubAccountsResponse) => {
      if (!data) {
        return;
      }

      const mappedServiceAccounts = data.service_accounts.map<SubAccount>(
        (acc) => {
          return {
            accountType: acc.account_type,
            emailId: acc.email_id,
            ownerName: acc.owner_name ?? undefined,
            roleName: acc.role_name,
            shopNames: acc.shop_names.filter(
              (shop): shop is SubAccountShop =>
                typeof shop.name === "string" && typeof shop.number === "number"
            ),
            userRoleId: acc.user_role_id,
          };
        }
      );

      return { ...data, service_accounts: mappedServiceAccounts };
    },
  });

  return {
    subAccounts: data?.service_accounts,
    nextPageCount: data?.nextPageCount,
  };
}

function parseParams(params?: UseSubAccountsParams) {
  if (!params) {
    return;
  }

  const parsedParams = new URLSearchParams();
  for (const key in params) {
    if (params[key as keyof UseSubAccountsParams]) {
      parsedParams.set(key, String(params[key as keyof UseSubAccountsParams]));
    }
  }
  return parsedParams.toString();
}
