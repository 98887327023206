import { AxiosInstance } from "axios";

import { FetchCCCHistoryResponse } from "@revv/data";

import { useStateContext } from "./context";
import { usePagedQuery } from "./usePagedQuery";

const pageSize = 20;
const baseUrl = "/v1/ccc_history/query/";

export type CCCHistoryParams = {
  shopId?: string;
  processingCode?: string;
  searchQuery?: string;
  sortBy?: string;
  sortOrder: "asc" | "desc" | "";
};

function convertParams(params: CCCHistoryParams) {
  const queryParams = new URLSearchParams();

  if (params?.sortBy) {
    queryParams.append("sortBy", params.sortBy);
  }
  if (params?.sortOrder) {
    queryParams.append("order", params.sortOrder);
  }

  const fields = [...queryParams.keys()];

  for (const key of fields) {
    if (!queryParams.get(key)) {
      queryParams.delete(key);
    }
  }

  return queryParams;
}

async function fetchCCCHistory(
  apiClient: AxiosInstance,
  pageNumber: number,
  params: CCCHistoryParams
) {
  if (!params.shopId || params.shopId === "0") {
    return;
  }
  const queryParams = convertParams(params);

  if (params.searchQuery) {
    queryParams.append("search", params.searchQuery);
  }

  if (params.processingCode) {
    queryParams.append("processingCode", params.processingCode.toString());
  }

  queryParams.append("pageNumber", pageNumber.toString());
  queryParams.append("pageSize", pageSize.toString());

  const res = await apiClient.get<FetchCCCHistoryResponse>(
    `${baseUrl}${params.shopId}`,
    {
      params: queryParams,
    }
  );

  return res.data;
}

export function useCCCHistory(
  params: CCCHistoryParams,
  onRefresh?: () => void
) {
  const { apiClient } = useStateContext();
  const queryKey = ["ccc-history", JSON.stringify([params])];

  const { getPage, count, error } = usePagedQuery(
    queryKey,
    async (pageNumber: number) => {
      const response = await fetchCCCHistory(apiClient, pageNumber, params);

      return {
        items: response?.data ?? [],
        count: response?.totalCount ?? 0,
      };
    },
    onRefresh
  );

  return {
    getPage,
    count,
    pageSize,
    error,
  };
}
