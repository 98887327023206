import { useQuery } from "@tanstack/react-query";

import { Permission } from "@revv/data";

import { useStateContext } from "./context";

const BASE_URL = "/v1/user_management";

type PermissionsListResponse = {
  permissions: Permission[];
};

export function usePermissionsList() {
  const { apiClient } = useStateContext();

  const { data, refetch, error, isLoading } = useQuery<
    PermissionsListResponse,
    Error
  >({
    refetchOnWindowFocus: false,
    queryKey: ["permissionsList"],
    queryFn: async () => {
      try {
        const response = await apiClient.get<PermissionsListResponse>(
          `${BASE_URL}/permissions`
        );
        return { permissions: response.data.permissions };
      } catch {
        throw new Error("Failed to fetch permissions");
      }
    },
  });

  return {
    permissionsList: data?.permissions,
    isLoading,
    error,
    refetch,
  };
}
