import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useMemo } from "react";

import { Shop, UserPermissions } from "@revv/data";

import { useStateContext } from "./context";
import { useCustomer } from "./useCustomer";

const BASE_URL = "/v1/shop/getAllShops";

type ShopsResponse = {
  shops: Shop[];
  nextPageCount: number;
};

type UseShopsOptions = {
  enablePlaceholderData?: boolean;
};

export function useShops(
  masterEmail = "",
  pageNumber = 1,
  pageSize = 10,
  filter = "",
  options: UseShopsOptions = {}
) {
  const { enablePlaceholderData = false } = options;

  const { apiClient } = useStateContext();
  const { customerInfo } = useCustomer();

  const isUserAuthorized = useMemo(
    () => customerInfo?.permissions?.includes(UserPermissions.VIEW_ALL_SHOPS),
    [customerInfo]
  );

  const { data, refetch, error, isLoading } = useQuery<ShopsResponse, Error>({
    refetchOnWindowFocus: false,
    queryKey: ["shops", masterEmail, pageNumber, pageSize, filter],
    queryFn: async () => {
      try {
        const response = await apiClient.get<ShopsResponse>(
          `${BASE_URL}/${masterEmail}?pageNumber=${pageNumber}&pageSize=${pageSize}&filter=${filter}`
        );
        return response.data;
      } catch (error: unknown) {
        throw error instanceof AxiosError
          ? new Error(error.response?.data.error)
          : new Error("Something went wrong");
      }
    },
    enabled: isUserAuthorized,
    placeholderData: enablePlaceholderData ? keepPreviousData : undefined,
  });

  return {
    shops: isUserAuthorized ? data?.shops : customerInfo?.shops,
    nextPageCount: data?.nextPageCount,
    isLoading,
    shopsError: error,
    refetch,
  };
}
