import { useQuery } from "@tanstack/react-query";

import { ListModelsResponse } from "@revv/data";

import { useStateContext } from "../context";

const baseUrl = "/v1/models";

export function useModels(makeId?: string) {
  const { apiClient } = useStateContext();

  const { data, error, isLoading } = useQuery({
    refetchOnWindowFocus: false,
    queryKey: ["models", makeId],
    queryFn: async () => {
      if (makeId) {
        const queryParams = new URLSearchParams();

        queryParams.append("makeId.equals", makeId);

        const response = await apiClient.get<ListModelsResponse>(baseUrl, {
          params: queryParams,
        });

        return response.data.models;
      }

      return [];
    },
  });

  return {
    models: data,
    error,
    isLoading,
  };
}
