import {
  useIsMutating,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { AxiosInstance } from "axios";

import { AccountType, GetMasterAccountsResponse } from "@revv/data";

import { useStateContext } from "./context";
import { useCustomer } from "./useCustomer";

const pageSize = 100;

export function useSuperAdmin(email: string) {
  const { apiClient } = useStateContext();
  const queryClient = useQueryClient();
  const { userContext } = useCustomer();

  const { data, error, isLoading } = useQuery({
    queryKey: ["master-accounts", email],
    enabled: userContext === AccountType.SUPERADMIN,
    queryFn: async () => {
      const res = await fetchMasterAccounts(apiClient, email);

      return res.master_accounts;
    },
  });

  const mutationKey = ["impersonate"];

  const impersonateMutation = useMutation({
    mutationKey,
    mutationFn: async (email: string) => {
      await apiClient.get(
        `/v1/customer/impersonate/${encodeURIComponent(email)}`
      );
    },
    onSuccess: async () => {
      // Reloading customer info to get the new selected master
      queryClient.invalidateQueries({ queryKey: ["customer", email] });
    },
  });

  const isImpersonating = useIsMutating({ mutationKey }) > 0;

  return {
    masterAccounts: data,
    isLoading,
    error,
    impersonateMutation: {
      mutateAsync: impersonateMutation.mutateAsync,
      isPending: impersonateMutation.isPending,
      error: impersonateMutation.error || undefined,
    },
    isImpersonating,
  };
}

async function fetchMasterAccounts(apiClient: AxiosInstance, email: string) {
  const queryParams = new URLSearchParams();

  // Hardcoding this for now
  queryParams.append("pageNumber", "1");
  queryParams.append("pageSize", pageSize.toString());

  const { data } = await apiClient.get<GetMasterAccountsResponse>(
    `/v1/customer/master_accounts/${encodeURIComponent(email)}?${queryParams.toString()}`
  );

  return data;
}
