import { useQuery } from "@tanstack/react-query";

import { ListCategoriesResponse } from "@revv/data";

import { useStateContext } from "../context";

const baseUrl = "/v1/sensors/categories";

export function useCategories() {
  const { apiClient } = useStateContext();

  const { data, error, isLoading } = useQuery({
    refetchOnWindowFocus: false,
    queryKey: ["category"],
    queryFn: async () => {
      const response = await apiClient.get<ListCategoriesResponse>(baseUrl);

      return response.data.categories;
    },
  });

  return {
    categories: data,
    error,
    isLoading,
  };
}
