import { useMutation } from "@tanstack/react-query";
import { AxiosInstance } from "axios";

import { ListReportsResponse, UploadEstimateResponse } from "@revv/data";

import { useStateContext } from "../context";
import { usePagedQuery } from "../usePagedQuery";

const pageSize = 100;
export const baseUrl = "/v1/reports";

export type ReportsFilterParams = {
  vin?: string;
  customerEmail?: string;
  masterEmail?: string;
  source?: string;
};

export type ReportParams = ReportsFilterParams & {
  sortBy: string;
  sortOrder: "asc" | "desc" | "";
};

export function useReports(params: ReportParams, onRefresh?: () => void) {
  const { apiClient } = useStateContext();

  const queryKey = ["reports", JSON.stringify(params)];

  const { getPage, count, error } = usePagedQuery(
    queryKey,
    async (pageNumber: number) => {
      const res = await fetchReports(apiClient, params, pageNumber);

      return {
        items: res.reports,
        count: res.count,
      };
    },
    onRefresh
  );

  const uploadMutation = useMutation({
    mutationFn: async (file: File) => {
      const data = new FormData();

      data.append("file", file);

      const res = await apiClient.post<UploadEstimateResponse>(baseUrl, data, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      return res.data;
    },
  });

  return {
    getPage,
    count,
    pageSize,
    error,
    uploadMutation: {
      mutateAsync: uploadMutation.mutateAsync,
      isPending: uploadMutation.isPending,
      error: uploadMutation.error || undefined,
    },
  };
}

async function fetchReports(
  apiClient: AxiosInstance,
  params: ReportParams,
  pageNumber: number
) {
  const queryParams = convertParams(params);

  queryParams.append("pageNumber", pageNumber.toString());
  queryParams.append("pageSize", pageSize.toString());

  const res = await apiClient.get<ListReportsResponse>(baseUrl, {
    params: queryParams,
  });

  return res.data;
}

function convertParams(params: ReportParams) {
  const queryParams = new URLSearchParams();

  queryParams.append("vin.contains", params.vin ?? "");
  queryParams.append("customerEmail.contains", params.customerEmail ?? "");
  queryParams.append("masterEmail.contains", params.masterEmail ?? "");
  queryParams.append("source.equals", params.source ?? "");
  queryParams.append("sortBy", params.sortBy);
  queryParams.append("order", params.sortOrder);

  const fields = [...queryParams.keys()];

  for (const key of fields) {
    if (!queryParams.get(key)) {
      queryParams.delete(key);
    }
  }

  return queryParams;
}
