import { AxiosInstance } from "axios";

import { ListRuleSetsResponse } from "@revv/data";

import { useStateContext } from "../context";
import { usePagedQuery } from "../usePagedQuery";

const pageSize = 100;
const baseUrl = "/v1/rule-sets";

export type RuleSetsFilterParams = {
  id?: string;
  description?: string;
  type?: string;
};

export type RuleSetsParams = RuleSetsFilterParams & {
  sortBy: string;
  sortOrder: "asc" | "desc" | "";
};

export function useRuleSets(
  sandboxId: string,
  params: RuleSetsParams,
  onRefresh?: () => void
) {
  const { apiClient } = useStateContext();

  const queryKey = ["rule-sets", sandboxId, JSON.stringify(params)];

  const { getPage, count, error } = usePagedQuery(
    queryKey,
    async (pageNumber: number) => {
      const res = await fetchRuleSets(apiClient, sandboxId, params, pageNumber);

      return {
        items: res.ruleSets,
        count: res.count,
      };
    },
    onRefresh
  );

  return {
    getPage,
    count,
    pageSize,
    error,
  };
}

async function fetchRuleSets(
  apiClient: AxiosInstance,
  sandboxId: string,
  params: RuleSetsParams,
  pageNumber: number
) {
  const queryParams = convertParams(params);

  queryParams.append("pageNumber", pageNumber.toString());
  queryParams.append("pageSize", pageSize.toString());

  const res = await apiClient.get<ListRuleSetsResponse>(
    `${baseUrl}/${sandboxId}`,
    {
      params: queryParams,
    }
  );

  return res.data;
}

function convertParams(params: RuleSetsParams) {
  const queryParams = new URLSearchParams();

  queryParams.append("id.contains", params.id ?? "");
  queryParams.append("description.contains", params.description ?? "");
  queryParams.append("type.equals", params.type ?? "");
  queryParams.append("sortBy", params.sortBy);
  queryParams.append("order", params.sortOrder);

  const fields = [...queryParams.keys()];

  for (const key of fields) {
    if (!queryParams.get(key)) {
      queryParams.delete(key);
    }
  }

  return queryParams;
}
