import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useMemo } from "react";

import {
  Service,
  ServiceCalibrationType,
  ServiceOperationType,
  ServicesQueryData,
  ServicesResponse,
  ServiceType,
  UpdateServiceResponse,
} from "@revv/data";
import { filterServiceByValue } from "@revv/utils";

import { useStateContext } from "./context";

export type UseServicesParams = {
  selectedShopNumber: number | undefined;
  selectedCalibrationTypeFilter: ServiceCalibrationType;
  selectedOperationTypeFilter: ServiceOperationType | undefined;
  selectedServiceTypeFilter: ServiceType | undefined;
};

export function useServices({
  selectedShopNumber,
  selectedCalibrationTypeFilter,
  selectedOperationTypeFilter,
  selectedServiceTypeFilter,
}: UseServicesParams) {
  const { apiClient } = useStateContext();
  const queryClient = useQueryClient();

  const queryKey = ["AutoQuoting", "Services", selectedShopNumber];

  const { data, error, isLoading } = useQuery<ServicesResponse>({
    queryKey,
    queryFn: async () => {
      if (selectedShopNumber === undefined) {
        return {
          defaultServices: [],
          customServices: [],
        };
      }

      const { data } = await apiClient.get<ServicesResponse>(
        `/v1/services/queryServices/${selectedShopNumber}`
      );

      return data;
    },
  });

  const processedServices = useMemo(() => {
    const initialData: ServicesQueryData = {
      [ServiceOperationType.ADAS]: {
        [ServiceType.DEFAULT]: [],
        [ServiceType.CUSTOM]: [],
      },
      [ServiceOperationType.STEERING]: {
        [ServiceType.DEFAULT]: [],
        [ServiceType.CUSTOM]: [],
      },
      [ServiceOperationType.SAFETY]: {
        [ServiceType.DEFAULT]: [],
        [ServiceType.CUSTOM]: [],
      },
      [ServiceOperationType.FUNCTIONAL]: {
        [ServiceType.DEFAULT]: [],
        [ServiceType.CUSTOM]: [],
      },
    };

    if (!data) {
      return initialData;
    }

    return [
      ...data.defaultServices,
      ...data.customServices,
    ].reduce<ServicesQueryData>(
      (groupedServices, service) => {
        const next = Object.assign({}, groupedServices);

        if (
          filterServiceByValue<ServiceType | undefined>(
            service.serviceType,
            selectedServiceTypeFilter
          ) &&
          filterServiceByValue<ServiceOperationType | undefined>(
            service.operationType,
            selectedOperationTypeFilter
          ) &&
          filterServiceByValue<ServiceCalibrationType>(
            service.calibrationType,
            selectedCalibrationTypeFilter,
            { shouldPassOnUndefined: false }
          )
        ) {
          next[service.operationType][service.serviceType].push(service);
        }

        return next;
      },
      Object.assign({}, initialData)
    );
  }, [
    data,
    selectedCalibrationTypeFilter,
    selectedOperationTypeFilter,
    selectedServiceTypeFilter,
  ]);

  const defaultServicesCount = data?.defaultServices.length ?? 0;

  const customServicesCount = data?.customServices.length ?? 0;

  function updateServiceData(serviceData: Service) {
    queryClient.setQueryData<ServicesResponse>(queryKey, (data) => {
      if (!data) {
        return;
      }

      const serviceTypeProperty: keyof ServicesResponse =
        serviceData.serviceType === ServiceType.DEFAULT
          ? "defaultServices"
          : "customServices";

      return {
        ...data,
        [serviceTypeProperty]: data[serviceTypeProperty].map((service) => {
          if (service.id === serviceData.id) {
            return { ...serviceData };
          }

          return service;
        }),
      };
    });
  }

  const updateServiceMutation = useMutation({
    mutationFn: async (service: Service) => {
      const { data } = await apiClient.post<UpdateServiceResponse>(
        "/v1/services/updateService",
        service
      );

      return data.service;
    },
    onSuccess: (service) => {
      updateServiceData(service);
    },
  });

  return {
    services: processedServices,
    defaultServicesCount,
    error,
    customServicesCount,
    isLoading,
    updateServiceMutation,
  };
}
