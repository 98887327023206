import { useMutation } from "@tanstack/react-query";
import { AxiosInstance } from "axios";

import { InvoiceCSVReportParams, InvoicesApiResponse } from "@revv/data";

import { useStateContext } from "./context";
import { usePagedQuery } from "./usePagedQuery";

const pageSize = 20;
const baseUrl = "/v1/invoices";

type CreateInvoiceParams = {
  vin: string;
  shop_number: number;
  repair_order: string;
  status: string;
  primary_billing_email: string;
  secondary_billing_emails: string[];
  due_date: string;
  vehicle: string;
  email_id: string;
  shipping_name: string;
  shipping_street: string;
  shipping_city: string;
  shipping_state: string;
  shipping_postal_code: number | null;
  shipping_country: string;
  tracking_number: number | null;
  mileage: number | null;
};

export type InvoiceParams = {
  emailId: string;
  searchQuery?: string;
  startDateFilter?: string;
  endDateFilter?: string;
  shopFilters?: string[];
  technicianFilter?: string;
  sortBy?: string;
  sortOrder?: "asc" | "desc" | "";
};

type UseInvoiceParams = {
  params: InvoiceParams;
  decryptData: (data: string) => InvoicesApiResponse;
  onRefresh?: () => void;
};

type HandleCSVDownloadMutationParams = {
  apiQueryParams: InvoiceCSVReportParams & {
    shop_filters?: string[];
  };
  fileName: string;
};

function convertParams(params: InvoiceParams) {
  const queryParams = new URLSearchParams();

  if (params?.emailId) {
    queryParams.append("email_id", params.emailId);
  }

  if (params?.sortBy) {
    queryParams.append("sortBy", params.sortBy);
  }
  if (params?.sortOrder) {
    queryParams.append("order", params.sortOrder);
  }

  const fields = [...queryParams.keys()];

  for (const key of fields) {
    if (!queryParams.get(key)) {
      queryParams.delete(key);
    }
  }

  return queryParams;
}

async function fetchInvoices(
  apiClient: AxiosInstance,
  decryptData: (data: string) => InvoicesApiResponse,
  pageNumber: number,
  params: InvoiceParams
): Promise<InvoicesApiResponse | undefined> {
  if (!params.emailId) {
    return;
  }

  const queryParams = convertParams(params);

  queryParams.append("pageNumber", pageNumber.toString());
  queryParams.append("pageSize", pageSize.toString());

  if (params.searchQuery && params?.searchQuery !== "") {
    queryParams.append("search_query", params.searchQuery);
  }

  if (params.startDateFilter) {
    queryParams.append("start_date_filter", params.startDateFilter);
  }

  if (params.endDateFilter) {
    queryParams.append("end_date_filter", params.endDateFilter);
  }

  if (params.shopFilters && params.shopFilters.length > 0) {
    params.shopFilters.forEach((value) =>
      queryParams.append("shop_filters[]", value)
    );
  }

  if (params.technicianFilter) {
    queryParams.append("technician_email_filter", params.technicianFilter);
  }

  const res = await apiClient.get(`${baseUrl}/all`, {
    params: queryParams,
  });

  const decryptedResponse = decryptData(res.data);
  return decryptedResponse;
}

export function useInvoices({
  params,
  decryptData,
  onRefresh,
}: UseInvoiceParams) {
  const { apiClient } = useStateContext();
  const queryKey = ["invoices", JSON.stringify([params])];

  const { getPage, count, error } = usePagedQuery(
    queryKey,
    async (pageNumber: number) => {
      const response = await fetchInvoices(
        apiClient,
        decryptData,
        pageNumber,
        params
      );

      return {
        items: response?.invoices ?? [],
        count: response?.totalCount ?? 0,
      };
    },
    onRefresh
  );

  const getStripeLinkMutation = useMutation({
    mutationFn: async () => {
      const queryParams = convertParams({ emailId: params.emailId });
      const res = await apiClient.get(
        `${baseUrl}/create_stripe_express_dashboard_link`,
        {
          params: queryParams,
        }
      );
      return res.data;
    },
  });

  const downloadCsvMutation = useMutation({
    mutationFn: async ({ apiQueryParams }: HandleCSVDownloadMutationParams) => {
      const res = await apiClient.get(`${baseUrl}/downloadCsv`, {
        params: apiQueryParams,
        responseType: "blob",
      });

      return res;
    },
  });

  const connectMutation = useMutation({
    mutationFn: async () => {
      const payload = {
        email_id: params.emailId,
      };

      const res = await apiClient.post(
        `${baseUrl}/enable_stripe_connect`,
        payload
      );
      return res.data;
    },
  });

  const createInvoiceMutation = useMutation({
    mutationFn: async (payload: CreateInvoiceParams) => {
      const res = await apiClient.post(
        `${baseUrl}/create-without-estimate`,
        payload
      );

      const decryptedData = decryptData(res.data);
      return decryptedData;
    },
  });

  const editInvoiceMutation = useMutation({
    mutationFn: async (payload: CreateInvoiceParams) => {
      const res = await apiClient.post(`${baseUrl}/edit`, payload);

      return res.data;
    },
  });

  return {
    getPage,
    count,
    error,
    pageSize,
    getStripeLinkMutation: {
      mutateAsync: getStripeLinkMutation.mutateAsync,
      isPending: getStripeLinkMutation.isPending,
      error: getStripeLinkMutation.error || undefined,
    },
    downloadCsvMutation: {
      mutateAsync: downloadCsvMutation.mutateAsync,
      isPending: downloadCsvMutation.isPending,
      error: downloadCsvMutation.error || undefined,
    },
    connectMutation: {
      mutateAsync: connectMutation.mutateAsync,
      isPending: connectMutation.isPending,
      error: connectMutation.error || undefined,
    },
    createInvoiceMutation: {
      mutateAsync: createInvoiceMutation.mutateAsync,
      isPending: createInvoiceMutation.isPending,
      error: createInvoiceMutation.error || undefined,
    },
    editInvoiceMutation: {
      mutateAsync: editInvoiceMutation.mutateAsync,
      isPending: editInvoiceMutation.isPending,
      error: editInvoiceMutation.error || undefined,
    },
  };
}
