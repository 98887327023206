import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useEffect } from "react";

import { useStateContext } from "./context";

const URL = "/";

export function useSystemStatus() {
  const { apiClient } = useStateContext();

  const { data, refetch, error, isLoading } = useQuery({
    refetchOnWindowFocus: true,
    queryKey: ["system-status"],
    queryFn: async () => {
      const response = await apiClient.get(URL);

      return response.status;
    },
  });

  useEffect(() => {
    const handlerId = apiClient.interceptors.response.use(
      (res) => res,
      async (error) => {
        if (
          error instanceof AxiosError &&
          error.response?.status === 503 &&
          error.config?.url !== URL
        ) {
          // Refetch status endpoint if it was any other endpoint
          refetch();
        }

        throw error;
      }
    );

    return () => {
      apiClient.interceptors.response.eject(handlerId);
    };
  }, [apiClient, refetch]);

  let status = data;

  if (error && error instanceof AxiosError) {
    // Requests might fail because of CORS or any other network related reasons
    // We treat those as 503 currently
    status = error.response?.status ?? 503;
  }

  return {
    status: status,
    refetch,
    isLoading,
  };
}
