import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import {
  CreateRuleSetRequest,
  CreateRuleSetResponse,
  DeleteRuleSetRequest,
  DeleteRuleSetResponse,
  GetRuleSetWithRefsResponse,
  GetRuleSetWithValuesResponse,
  UpdateRuleSetRequest,
  UpdateRuleSetResponse,
} from "@revv/data";

import { useStateContext } from "../context";

const baseUrl = "/v1/rule-sets";

export function useRuleSetWithValues(sandboxId: string, id: string) {
  const { apiClient } = useStateContext();
  const queryKey = ["ruleSets", sandboxId, id, "values"];

  const { data, error, isLoading } = useQuery({
    refetchOnWindowFocus: false,
    queryKey: queryKey,
    queryFn: async () => {
      const response = await apiClient.get<GetRuleSetWithValuesResponse>(
        `${baseUrl}/${sandboxId}/${id}?mode=values`
      );

      return response.data.ruleSet;
    },
  });

  return {
    ruleSet: data,
    error,
    isLoading,
  };
}

export function useRuleSet(sandboxId: string, id: "new" | string) {
  const { apiClient } = useStateContext();
  const queryClient = useQueryClient();
  const queryKey = ["ruleSets", sandboxId, id, "refs"];

  const { data, error, isLoading } = useQuery({
    refetchOnWindowFocus: false,
    queryKey: queryKey,
    enabled: id !== "new",
    queryFn: async () => {
      const response = await apiClient.get<GetRuleSetWithRefsResponse>(
        `${baseUrl}/${sandboxId}/${id}?mode=refs`
      );

      return response.data.ruleSet;
    },
  });

  const updateMutation = useMutation({
    mutationFn: async (payload: UpdateRuleSetRequest) => {
      const res = await apiClient.put<UpdateRuleSetResponse>(
        `${baseUrl}/${sandboxId}/${id}`,
        payload
      );

      return res.data;
    },
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey });
      queryClient.invalidateQueries({
        queryKey: ["rule-sets"],
        exact: false,
      });
    },
  });

  const createMutation = useMutation({
    mutationFn: async (payload: CreateRuleSetRequest) => {
      const res = await apiClient.post<CreateRuleSetResponse>(
        `${baseUrl}/${sandboxId}`,
        payload
      );

      return res.data;
    },
    onSuccess: async () => {
      queryClient.invalidateQueries({
        queryKey: ["rule-sets"],
        exact: false,
      });
    },
  });

  const deleteMutation = useMutation({
    mutationFn: async (payload: DeleteRuleSetRequest) => {
      const res = await apiClient.delete<DeleteRuleSetResponse>(
        `${baseUrl}/${sandboxId}/${id}`,
        {
          data: payload,
        }
      );

      return res.data;
    },
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey });
      queryClient.invalidateQueries({
        queryKey: ["rule-sets"],
        exact: false,
      });
    },
  });

  return {
    ruleSet: data,
    error,
    isLoading,
    updateMutation: {
      mutateAsync: updateMutation.mutateAsync,
      isPending: updateMutation.isPending,
      error: updateMutation?.error || undefined,
    },
    createMutation: {
      mutateAsync: createMutation.mutateAsync,
      isPending: createMutation.isPending,
      error: createMutation?.error || undefined,
    },
    deleteMutation: {
      mutateAsync: deleteMutation.mutateAsync,
      isPending: deleteMutation.isPending,
      error: deleteMutation?.error || undefined,
    },
  };
}
